import AlertMessagesProvider from 'react-alert-messages';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ROUTES } from './const';
import {
  Dashboard as DashboardPage,
  PageNotFound as PageNotFoundPage,
  Shops as ShopsPage,
  Login as LoginPage,
  Vouchers as VouchersPage,
} from './components/pages';
import ConsoleBase from './components/utils/ConsoleBase/ConsoleBase';
import ApplicationProvider from './contexts/ApplicationContext';
import './App.css';

function App() {
  return (
    <AlertMessagesProvider>
      <BrowserRouter>
        <ApplicationProvider>
          <Routes>
            <Route exact path={ROUTES.BASE} element={<Navigate to={ROUTES.LOGIN} replace={true} />} />
            <Route exact path={ROUTES.LOGIN} Component={LoginPage} />
            <Route exact path={ROUTES.PAGE_404} Component={PageNotFoundPage} />
            <Route path={ROUTES.CONSOLE} Component={ConsoleBase}>
              <Route exact path={ROUTES.CONSOLE} Component={DashboardPage} />
              <Route exact path={ROUTES.SHOPS} Component={ShopsPage} />
              <Route exact path={ROUTES.VOUCHERS} Component={VouchersPage} />
            </Route>
            <Route path={ROUTES.ANY} element={<Navigate to={ROUTES.PAGE_404} replace={true} />} />
          </Routes>
        </ApplicationProvider>
      </BrowserRouter>
    </AlertMessagesProvider>
  );
}

export default App;
