import styles from './styles.module.css';
import React, { useContext } from 'react';
import { ApplicationContext } from '../../../contexts/ApplicationContext';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../const';

export default function TitleBar() {
  const { showMenu, setShowMenu } = useContext(ApplicationContext);

  const handleMenuBtn = () => {
    setShowMenu(!showMenu);
  };

  let menuClasses;
  if (showMenu) {
    menuClasses = `${styles.menuBtnBurger} ${styles.menuBtnBurgerOpen}`;
  } else {
    menuClasses = `${styles.menuBtnBurger}`;
  }
  return (
    <div className={styles.titleBarLayout}>
      <div className={styles.logoWrapper}>
        <div className={styles.menuBtn}>
          <span className={menuClasses} onClick={handleMenuBtn}></span>
        </div>
        <Link to={ROUTES.SHOPS} style={{ textDecorationColor: 'transparent' }}>
          <span className={styles.logoTextColor}>SANOFT</span>
          <span className={styles.logoText}>POS ADMIN</span>
        </Link>
      </div>
    </div>
  );
}
