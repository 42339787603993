import React, { useState, useEffect } from 'react';
import styles from './styles.module.css';
import { TextField, Grid2, InputAdornment, Box, Button } from '@mui/material';
import Loading from '../../utils/Loading';
import AuthService from '../../../services/AuthService';
import { useNavigate } from 'react-router-dom';
import { STORAGE_KEYS, ROUTES } from '../../../const';
import usernameIcon from './person.svg';
import passwordIcon from './lock.svg';

export default function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem(STORAGE_KEYS.TOKEN);
    if (token) {
      navigate(ROUTES.SHOPS);
    }
  }, [navigate]);

  const loginBtnPressed = async (e) => {
    if (!username || !password) {
      setErrorMessage('Username and Password should not be empty..');
      return;
    }
    setLoadingIndicator(true);
    const error = await AuthService.login(username, password);
    setLoadingIndicator(false);
    if (error) {
      setErrorMessage('Failed to login!');
    } else {
      navigate(ROUTES.CONSOLE);
    }
  };

  return (
    <Grid2 className={styles.mainDiv}>
      <Box container="section" className={styles.outerBoxFrame}>
        <Grid2 style={{ boxShadow: 3 }} xs={12} sm={12} className={styles.formBoxFrame}>
          <Grid2>
            <Grid2 className={styles.welcome}>
              <span style={{ color: '#3F8DCE' }}>SANOFT</span>
              <span style={{ color: '#3C465B', marginLeft: 10 }}>POS ADMIN</span>
            </Grid2>
            <div className={styles.textCenter}>
              <TextField
                name="username"
                size="small"
                label="Username"
                type="text"
                placeholder="type your username"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img alt="" src={usernameIcon} width="18" height="18" />
                    </InputAdornment>
                  ),
                }}
                onChange={(event) => {
                  setErrorMessage(null);
                  setUsername(event.target.value);
                }}
                autoComplete="current-username"
                color="primary"
                fullWidth
              />
            </div>
            <div className={styles.textCenter}>
              <TextField
                name="password"
                size="small"
                label="Password"
                type="password"
                margin="normal"
                placeholder="type your password"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img alt="" src={passwordIcon} width="18" height="18" />
                    </InputAdornment>
                  ),
                }}
                onChange={(event) => {
                  setErrorMessage(null);
                  setPassword(event.target.value);
                }}
                autoComplete="current-password"
                color="primary"
                fullWidth
              />
            </div>
            {errorMessage && <span className={styles.errorMsg}>{errorMessage}</span>}

            <div className={styles.loginLoader}>{loadingIndicator && <Loading />}</div>
            <Grid2 className={styles.textCenter}>
              <Button
                className={styles.loginButton}
                variant="contained"
                color="secondary"
                type="submit"
                onClick={loginBtnPressed}
                disabled={loadingIndicator}
                fullWidth
                style={{ backgroundColor: '#3f8dce' }}
              >
                {loadingIndicator ? 'Loagging in...' : 'Login'}
              </Button>
            </Grid2>
          </Grid2>
        </Grid2>
      </Box>
    </Grid2>
  );
}
