import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Grid2 } from '@mui/material';
import {
  Key as KeyIcon,
  Store as StoreIcon,
  LogoutOutlined as LogoutOutlinedIcon,
  Dashboard as DashboardIcon,
} from '@mui/icons-material';
import { ROUTES } from '../../../const';
import { ApplicationContext } from '../../../contexts/ApplicationContext';
import AuthService from '../../../services/AuthService';
import styles from './styles.module.css';

const tabStyleActive = {
  backgroundColor: '#03204C',
  color: '#fff',
};

const tabStyle = {};
export default function NavBar() {
  const { selectedTab, setSelectedTab } = useContext(ApplicationContext);
  const handleSelectedTabChange = (tab) => {
    setSelectedTab(tab);
  };
  const initiateLogout = async (e) => {
    await AuthService.logout();
    window.location.reload();
  };

  const navigationItems = [
    {
      link: ROUTES.CONSOLE,
      slug: 'dashboard',
      icon: DashboardIcon,
      label: 'DASHBOARD',
    },
    {
      link: ROUTES.SHOPS,
      slug: 'shops',
      icon: StoreIcon,
      label: 'SHOPS',
    },
    {
      link: ROUTES.VOUCHERS,
      slug: 'vouchers',
      icon: KeyIcon,
      label: 'VOUCHERS',
      hide: true,
    },
  ];

  return (
    <Grid2 container spacing={1}>
      <Grid2 xs={12} className={styles.navBar}>
        <div>
          {navigationItems
            .filter((i) => !i.hide)
            .map((navigationItem) => (
              <Link to={navigationItem.link} style={{ textDecorationColor: 'transparent' }}>
                <div
                  className={`${styles.navItem} ${styles.mt - 4}`}
                  style={selectedTab === navigationItem.slug ? tabStyleActive : tabStyle}
                  onClick={() => {
                    handleSelectedTabChange(navigationItem.slug);
                  }}
                >
                  <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                    <navigationItem.icon fontSize="small" className={styles.navBarIconColor} />
                  </span>
                  <span className={styles.title}>{navigationItem.label}</span>
                </div>
              </Link>
            ))}
        </div>
        <Link key="logout" className={styles.logoutWrapper} onClick={initiateLogout}>
          <LogoutOutlinedIcon />
          <span className={styles.navLinkText}>Logout</span>
        </Link>
      </Grid2>
    </Grid2>
  );
}
