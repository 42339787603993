import BackendService from './BackendService';

async function getUserProfile() {
  return BackendService.getData(`/profile/`);
}

const DealerServices = {
  getUserProfile,
};

export default DealerServices;
