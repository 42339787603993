import BackendService from './BackendService';
import { HEADERS, STORAGE_KEYS } from '../const';

async function login(username, password) {
  const reqData = { username, password };
  try {
    const resp = await BackendService.postData('/login/', reqData, {
      'User-Interface': 'ios',
    });

    const token = resp.data.auth_token;
    if (!token) {
      return new Error('Failed generate auth token from server');
    }
    localStorage.setItem(STORAGE_KEYS.TOKEN, token);
  } catch (error) {
    return error;
  }
}
async function logout() {
  const token = localStorage.getItem(STORAGE_KEYS.TOKEN);
  localStorage.clear();

  if (!token) return;
  try {
    return await BackendService.destroy('/logout/', null, {
      [HEADERS.AUTH]: `Token ${token}`,
    });
  } catch (error) {
    console.error(error);
  }
}

const AuthService = {
  login,
  logout,
};

export default AuthService;
