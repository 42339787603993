export const BACKEND_URL = process.env.REACT_APP_API_BASE_URL || 'https://api-pos-legacy.sanoft.com/dealer';

export const STORAGE_KEYS = {
  TOKEN: 'x-auth-token',
  USER_ID: 'x-auth-user-id',
  FIRST_NAME: 'x-first-name',
  LAST_NAME: 'x-last-name',
  USERNAME: 'x-username',
};

export const HEADERS = {
  AUTH: 'Authorization',
};

export const ROUTES = {
  LOGIN: '/login',
  RESET_PASSWORD: '/reset-password',
  BASE: '/',
  ANY: '*',
  CONSOLE: '/console',
  SHOPS: '/console/shops',
  VOUCHERS: '/console/vouchers',
  PAGE_404: '/page-404',
};
