import { useState, useCallback, useContext, useEffect } from 'react';
import { AlertMessagesContext } from 'react-alert-messages';
import DealerServices from '../../../services/DealerServices';
import Loader from '../../utils/Loading';

function DashboardPage() {
  const [userProfile, setUserProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { postAlertMessage } = useContext(AlertMessagesContext);

  const getUserProfile = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await DealerServices.getUserProfile();
      setUserProfile(res.data);
    } catch (error) {
      postAlertMessage({ text: error.message, type: 'failed' });
    }
    setIsLoading(false);
  }, [postAlertMessage]);

  useEffect(() => {
    getUserProfile();
  }, [getUserProfile]);

  const dealer = userProfile?.dealer;
  return (
    <div>
      <div>
        <h2>DASHBOARD</h2>
      </div>
      <div>
        <Loader isOpen={isLoading} />
        <div>
          <h3>Dealer</h3>
          <div style={{ maxWidth: 600 }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ flex: 0.4 }}>DEALER ID</div>
              <div style={{ flex: 0.6 }}>{dealer?.id ?? '-'}</div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ flex: 0.4 }}>DEALER NAME</div>
              <div style={{ flex: 0.6 }}>{dealer?.name ?? '-'}</div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ flex: 0.4 }}>BALANCE</div>
              <div style={{ flex: 0.6 }}>{dealer?.credit_balance ?? '-'}</div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ flex: 0.4 }}>CREDIT LIMIT</div>
              <div style={{ flex: 0.6 }}>{dealer?.credit_limit ?? '-'}</div>
            </div>
          </div>
        </div>
        <div>
          <h3>User profile</h3>
          <div style={{ maxWidth: 600 }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ flex: 0.4 }}>NAME</div>
              <div style={{ flex: 0.6 }}>{userProfile?.name ?? '-'}</div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ flex: 0.4 }}>USERNAME</div>
              <div style={{ flex: 0.6 }}>{userProfile?.username ?? '-'}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardPage;
