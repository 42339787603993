import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';

export default function ShopReset(props) {
  const { data, handleClose, handleOkay, resetType } = props;

  const [confirmText, setConfirmText] = useState('');

  return (
    <Dialog
      open={data}
      onClose={() => {
        handleClose();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {resetType === 'SOFT_RESET' ? 'Soft Reset Confirmation' : 'Hard Reset Confirmation'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {resetType === 'SOFT_RESET'
            ? `Confirm Soft Reset: This action will reset certain data, including the bill summary report. Please type 'SOFT_RESET' in uppercase to confirm.`
            : `Confirm Hard Reset: This action will permanently delete all shop data. Please type 'HARD_RESET' in uppercase to confirm.`}
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Enter confirmation text"
          type="text"
          value={confirmText}
          onChange={(e) => setConfirmText(e.target.value)}
          fullWidth
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleClose();
          }}
          color="primary"
        >
          cancel
        </Button>
        <Button
          onClick={() => {
            handleOkay(data, resetType);
            handleClose();
          }}
          color="primary"
          autoFocus
          disabled={confirmText !== resetType || confirmText !== resetType}
        >
          Reset shop
        </Button>
      </DialogActions>
    </Dialog>
  );
}
