import React, { createContext, useState } from 'react';
import { useLocation } from 'react-router';

export const ApplicationContext = createContext();

export default function ApplicationProvider(props) {
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState(location?.pathname.split('/')[2] || 'dashboard');
  const [showMenu, setShowMenu] = useState(window.innerWidth >= 600);

  return (
    <ApplicationContext.Provider value={{ selectedTab, setSelectedTab, showMenu, setShowMenu }}>
      {props.children}
    </ApplicationContext.Provider>
  );
}
