import React from 'react';
import { Close as CloseIcon } from '@mui/icons-material';
import { Button, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Grid2 } from '@mui/material';
import styles from './styles.moduel.css';

function ShopDetails({ handleClose, data, resetShop }) {
  return (
    <Dialog
      open={true}
      maxWidth="sm"
      fullWidth={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle className={styles.title} id="alert-dialog-title">
        <span>Shop Details</span>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          style={{
            position: 'absolute',
            top: '8px',
            right: '8px',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Grid2 container spacing={2} className={styles.inputRow} alignItems="center">
          <Grid2 item xs={5} className={styles.inputLabelContainer}>
            <span>Id</span>
          </Grid2>
          <Grid2 item xs={7}>
            <span style={{ color: '#3f8dce' }}>{data?.id || '-'}</span>
          </Grid2>
        </Grid2>
        <Grid2 container spacing={2} className={styles.inputRow} alignItems="center">
          <Grid2 item xs={5} className={styles.inputLabelContainer}>
            <span>Shop name</span>
          </Grid2>
          <Grid2 item xs={7} className={styles.data}>
            <span style={{ color: '#3f8dce' }}>{data?.shop_name || '-'}</span>
          </Grid2>
        </Grid2>
        <Grid2 container spacing={2} className={styles.inputRow} alignItems="center">
          <Grid2 item xs={5} className={styles.inputLabelContainer}>
            <span>Subscription</span>
          </Grid2>
          <Grid2 item xs={7}>
            <span style={{ color: '#3f8dce' }}>{data?.subscription?.name || '-'}</span>
          </Grid2>
        </Grid2>
        <Grid2 container spacing={2} className={styles.inputRow} alignItems="center">
          <Grid2 item xs={5} className={styles.inputLabelContainer}>
            <span>Dealer</span>
          </Grid2>
          <Grid2 item xs={7}>
            <span style={{ color: '#3f8dce' }}>{data?.dealer?.name || '-'}</span>
          </Grid2>
        </Grid2>
        <Grid2 container spacing={2} className={styles.inputRow} alignItems="center">
          <Grid2 item xs={5} className={styles.inputLabelContainer}>
            <span>Expense validity</span>
          </Grid2>
          <Grid2 item xs={7}>
            <span style={{ color: '#3f8dce' }}>{data?.expense_validity || '-'}</span>
          </Grid2>
        </Grid2>
        <Grid2 container spacing={2} className={styles.inputRow} alignItems="center">
          <Grid2 item xs={5} className={styles.inputLabelContainer}>
            <span>Image Support validity</span>
          </Grid2>
          <Grid2 item xs={7}>
            <span style={{ color: '#3f8dce' }}>{data?.image_support_validity || '-'}</span>
          </Grid2>
        </Grid2>
        <Grid2 container spacing={2} className={styles.inputRow} alignItems="center">
          <Grid2 item xs={5} className={styles.inputLabelContainer}>
            <span>Kot validity</span>
          </Grid2>
          <Grid2 item xs={7}>
            <span style={{ color: '#3f8dce' }}>{data?.kot_validity || '-'}</span>
          </Grid2>
        </Grid2>
        <Grid2 container spacing={2} className={styles.inputRow} alignItems="center">
          <Grid2 item xs={5} className={styles.inputLabelContainer}>
            <span>Kot lite validity</span>
          </Grid2>
          <Grid2 item xs={7}>
            <span style={{ color: '#3f8dce' }}>{data?.kot_lite_validity || '-'}</span>
          </Grid2>
        </Grid2>
        <Grid2 container spacing={2} className={styles.inputRow} alignItems="center">
          <Grid2 item xs={5} className={styles.inputLabelContainer}>
            <span>Smart scale validity</span>
          </Grid2>
          <Grid2 item xs={7}>
            <span style={{ color: '#3f8dce' }}>{data?.smart_scale_validity || '-'}</span>
          </Grid2>
        </Grid2>
        <Grid2 container spacing={2} className={styles.inputRow} alignItems="center">
          <Grid2 item xs={5} className={styles.inputLabelContainer}>
            <span>Mobile no</span>
          </Grid2>
          <Grid2 item xs={7}>
            <span style={{ color: '#3f8dce' }}>{data?.mobile_no || '-'}</span>
          </Grid2>
        </Grid2>
        <Grid2 container spacing={2} className={styles.inputRow} alignItems="center">
          <Grid2 item xs={5} className={styles.inputLabelContainer}>
            <span>Subscription End Date</span>
          </Grid2>
          <Grid2 item xs={7}>
            <span style={{ color: '#3f8dce' }}>{data?.subscription_end_date || '-'}</span>
          </Grid2>
        </Grid2>
        <Grid2 container spacing={2} className={styles.inputRow} alignItems="center">
          <Grid2 item xs={5} className={styles.inputLabelContainer}>
            <span>Currency</span>
          </Grid2>
          <Grid2 item xs={7}>
            <span style={{ color: '#3f8dce' }}>{data?.currency || '-'}</span>
          </Grid2>
        </Grid2>
        <Grid2 container spacing={2} className={styles.inputRow} alignItems="center">
          <Grid2 item xs={5} className={styles.inputLabelContainer}>
            <span>Additional sales balance</span>
          </Grid2>
          <Grid2 item xs={7}>
            <span style={{ color: '#3f8dce' }}>{data?.additional_sales_balance || 0}</span>
          </Grid2>
        </Grid2>
      </DialogContent>

      <DialogActions style={{ padding: '12px 22px', justifyContent: 'space-between' }}>
        <div>
          <Button variant="contained" color="secondary" onClick={() => resetShop('SOFT_RESET')}>
            Soft Reset
          </Button>
          <Button
            variant="contained"
            style={{ marginLeft: '12px' }}
            color="secondary"
            onClick={() => resetShop('HARD_RESET')}
          >
            Hard Reset
          </Button>
        </div>
        <div>
          <Button variant="contained" color="primary" onClick={handleClose}>
            Close
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default ShopDetails;
