import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Grid2 } from '@mui/material';
import NavBar from '../NavBar';
import TitleBar from '../TitleBar';
import { ROUTES, STORAGE_KEYS } from '../../../const';
import { ApplicationContext } from '../../../contexts/ApplicationContext';
import styles from './styles.module.css';

function ConsoleBase() {
  const { showMenu } = useContext(ApplicationContext);

  // Checking is user logged in
  const queryParams = new URLSearchParams(window.location.search);
  const paramsToken = queryParams.get('token');
  if (paramsToken) {
    localStorage.setItem(STORAGE_KEYS.TOKEN, paramsToken);
  }
  const token = paramsToken || localStorage.getItem(STORAGE_KEYS.TOKEN);
  if (!token) {
    return <Navigate to={ROUTES.LOGIN} />;
  }

  return (
    <>
      <TitleBar />
      <Grid2 container className={styles.ContentsWrapper}>
        {showMenu && (
          <Grid2 className={styles.NavbarWrapper}>
            <NavBar />
          </Grid2>
        )}
        <Grid2 className={showMenu ? styles.ContentsArea : styles.ContentsAreaFull}>
          <div className={styles.ContentsView}>
            <Outlet />
          </div>
        </Grid2>
      </Grid2>
    </>
  );
}

export default ConsoleBase;
