import BackendService from './BackendService';

async function getShops(params) {
  return BackendService.getData('/shops/', {}, params);
}
async function getShop(id) {
  return BackendService.getData(`/shops/${id}/`);
}
async function createShop(data) {
  return BackendService.postData('/shops/', data);
}
async function updateShop(id, data = {}) {
  return BackendService.patch(`/shops/${id}/`, data);
}
async function deleteShop(id) {
  return BackendService.destroy(`/shops/${id}/`);
}
async function hardResetShop(id) {
  return BackendService.destroy(`/shops/${id}/hard-reset/`);
}
async function softResetShop(id) {
  return BackendService.destroy(`/shops/${id}/soft-reset/`);
}

const ShopServices = {
  getShop,
  getShops,
  createShop,
  updateShop,
  deleteShop,
  hardResetShop,
  softResetShop,
};

export default ShopServices;
